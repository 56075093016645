import { alpha } from "@mui/system";
import { Theme, makeStyles } from "mui-styles";

export const useStyles = makeStyles((theme: Theme) => ({
  texfield: {
    color: theme.palette.mode === "light" ? undefined : "white",
    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "& .Mui-focused": {
      color: theme.palette.mode === "light" ? undefined : "white",
    },
    "& .MuiInputBase-root": {
      color: theme.palette.mode === "light" ? undefined : "white",
      borderRadius: 10,
      backgroundColor:
      theme.palette.mode === "light" ? undefined : alpha("#ffffff", 0.1),
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: theme.palette.mode === "light" ? undefined : "white",
        borderWidth: theme.palette.mode === "light" ? undefined : 0,
      },
      "&:hover fieldset": {
        borderColor:
          theme.palette.mode === "light"
            ? alpha("#019b61", 0.2)
            : alpha("#fff", 0.2),
        borderWidth: "0.13rem",
      },
      "&.Mui-focused fieldset": {
        borderColor:
          theme.palette.mode === "light"
            ? alpha("#019b61", 0.6)
            : alpha("#fff", 0.3),
        borderWidth: "0.15rem",
      },
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: theme.palette.mode === "light" ? undefined : alpha("#fff", 0.5),
    },
  },
  input: {
    color: theme.palette.mode === "dark" ? "white" : undefined,
    backgroundColor:
      theme.palette.mode === "light" ? undefined : alpha("#ffffff", 0.1),
  },
}));
