import { Theme, alpha } from "@mui/material";
import { makeStyles } from "mui-styles";

export const useStyles = makeStyles((theme: Theme) => ({
  outlined: {
    height: 44,
    border:
      theme.palette.mode === "light" ? `1px solid #fff` : `1px solid ${alpha("#019b61", 0.20)}`,
    boxSizing: "border-box",
    borderRadius: 20,
    color: theme.palette.mode === "light" ? undefined : alpha(theme.palette.common.white, 0.8),
    boxShadow: "0px 8px 24px -8px rgba(78, 125, 150, 0.24)",
    "&.MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: theme.palette.mode === "light" ? alpha('#E3E6E8', 0.5) : alpha(theme.palette.common.white, 0.2),
      border: "2px solid #017b50",
    },
    "&:hover .MuiButton-label": {},
  },
  outlinedPrimary: {
    height: 44,
    border: `1px solid #019b61`,
    boxSizing: "border-box",
    borderRadius: 20,
    boxShadow: "0px 8px 24px -8px rgba(78, 125, 150, 0.24)",
    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "#E3E6E8",
      border: "2px solid #E3E6E8",
    },
    "&:hover .MuiButton-label": {},
  },
  contained: {
    height: 44,
    backgroundColor: "#019b61",
    boxSizing: "border-box",
    color: "#fff",
    borderRadius: 20,
    boxShadow: `0px 4px 8px ${alpha("#019b61", 0.24)}`,
    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "#017b50",
      opacity: 0.9,
    },
    "&:hover .MuiButton-label": {},
  },
  text: {
    height: 44,
    boxSizing: "border-box",
    borderRadius: 20,
    boxShadow: "0px 8px 24px -8px rgba(78, 125, 150, 0.24)",
    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "#F3F3F3",
    },
    "&:hover .MuiButton-label": {},
  },
  cancel: {
    height: 44,
    border: "2px solid #fff",
    boxSizing: "border-box",
    color: "#595B5C",
    borderRadius: 20,
    boxShadow: "0px 8px 24px -8px rgba(78, 125, 150, 0.24)",
    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: "#E3E6E8",
      border: "2px solid #E3E6E8",
    },
    "&:hover .MuiButton-label": {},
  },
  delete: {
    height: 44,
    background: `${theme.palette.error.main}`,
    boxSizing: "border-box",
    color: "#fff",
    borderRadius: 20,
    boxShadow: "0px 4px 8px rgba(255, 51, 66, 0.24)",

    "& .MuiButton-label": {
      fontSize: 16,
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontStyle: "normal",
    },
    "&:hover": {
      backgroundColor: `${theme.palette.error.main}`,
      opacity: 0.7,
    },
    "&:hover .MuiButton-label": {},
  },
}));
