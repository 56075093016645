import JWT from "expo-jwt";
import { Bloc, getStore } from "react-stream-bloc";
import { socket } from "src/core/config/socket";
import { Dashboard } from "src/core/models/response_model";
import { User } from "src/core/models/user_model";
import AuthService from "src/core/services/user_service";
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "src/core/utils/localStorage";
import { AuthInitialState, AuthState } from "./auth_state";

export class AuthBloc extends Bloc<AuthState> {
  token?: string;
  user?: User;
  dashboard?: Dashboard;

  constructor(private service: AuthService) {
    super(AuthInitialState);
    this.getAuth();
  }

  getUser(): User | undefined {
    const userStore = getStore("m-access-data", false);

    const user = JWT.decode(userStore, "marketaria-encrypted-user");
    if (user) {
      const result = user as User;
      return result;
    } else {
      return undefined;
    }
  }

  async getAuth() {
    this.changeState({ ...this.state, loading: true });
    const token = await getLocalStorage("access-token");

    if (token) {
      this.changeState(this.mapToLoadedState(undefined, token));
    } else {
      this.changeState(this.mapToLoadedState(undefined, undefined));
    }
  }

  async getDashboard() {
    if (!this.dashboard) {
      const res = await this.service.getDashboard();
      this.changeState({ ...this.state, dashboard: res.data });
    }
  }

  async login(username: string, password: string): Promise<string | undefined> {
    this.changeState({ ...this.state, login: true });
    const params = {
      user: username,
      pass: password,
      client: 'cuevas'
    };
    const response = await this.service.loginUser(params);

    if (response.response === "success") {
      const token = response.data.authorization
      setLocalStorage("access-token", token);
      this.changeState(
        this.mapToLoadedState(response.data?.user, token)
      );
      return response.response;
    } else {
      this.changeState(this.mapToLoadedState());
      return response.response;
    }
  }

  async register(data: User): Promise<string | undefined> {
    this.changeState({ ...this.state, register: true });
    const response = await this.service.addUser(data);
    if (response.code === "success") {
      //if (response.data?.user && response.data.token) {
      //  setLocalStorage("m-access-id", response.data?.token);
      //  const user = JWT.encode(response.data?.user, "marketaria-encrypted-user");
      //  setLocalStorage("m-access-data", user);
      //
      //  this.changeState(
      //    this.mapToLoadedState(response.data?.user, response.data?.token)
      //  );
      //} else {
      //  this.changeState({ ...this.state, register: false });
      //}
      return response.code;
    } else {
      this.changeState(this.mapToLoadedState());
      return response.code;
    }
  }

  async setAuth(user: User) {
    const token = await getLocalStorage("m-access-id");
    if (token) {
      if (user) {
        const encode = JWT.encode(user, "marketaria-encrypted-user");
        setLocalStorage("m-access-data", encode);
      }
      this.changeState(this.mapToLoadedState(user, token));
    }
  }

  async setStatus(status?: string) {
    let user = this.user;

    if (user?.status) {
      user!.status = status;
    }
    if (user) {
      const encode = JWT.encode(user, "marketaria-encrypted-user");
      setLocalStorage("m-access-data", encode);
    }
    this.changeState({ ...this.state, user: user });
  }

  async setVerified(verified?: boolean) {
    let user = this.user;

    if (user?.verified) {
      user.verified = verified;
    }
    if (user) {
      const encode = JWT.encode(user, "marketaria-encrypted-user");
      setLocalStorage("m-access-data", encode);
    }
    this.changeState({ ...this.state, user: user });
  }

  async updateProfile(data?: User): Promise<string | undefined> {
    this.changeState({ ...this.state, updating: true });
    const response = await this.service.updateUser(data);

    if (response.code === "success") {
      const user = response.data;
      if (user) {
        const encode = JWT.encode(user, "marketaria-encrypted-user");
        setLocalStorage("m-access-data", encode);
      }
      this.changeState({ ...this.state, user: user, updating: false });
      return response.code;
    } else {
      return response.code;
    }
  }

  async logout() {
    this.changeState({ ...this.state, loading: true });
    clearLocalStorage();
    socket.removeAllListeners();
    socket.disconnect();
    socket.close();
    this.changeState(this.mapToLoadedState(undefined, undefined));
  }

  mapToLoadedState(data?: User, token?: string): AuthState {
    this.user = data;
    this.token = token;
    return {
      loading: false,
      deleting: false,
      updating: false,
      login: false,
      register: false,
      user: data,
      token: token,
    };
  }
}
